import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './academyBooking.css';
import { Box, Button, Card, Typography, Avatar, CardContent, IconButton, CssBaseline, Container, FormControl, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent, Paper, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { academyRegistrationAction } from '../../store/slices/academyregistration';
import { getacademyDetailsAction } from '../../store/slices/academyDetails';
import toast from 'react-hot-toast';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { supervisorrAcademySessionBookingAction } from '../../store/actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import qrCode from '../../assets/qr.png';

const validationSchema = Yup.object().shape({
  studentName: Yup.string().required('Student Name is required'),
  studentAge: Yup.number().positive('Age must be positive').required('Age is required'),
  gender: Yup.string().required('Gender is required'),
  studentSchool: Yup.string().required('School is required'),
  studentGrade: Yup.string().required('Grade is required'),
  fatherName: Yup.string().required("Father's Name is required"),
  fatherMobile: Yup.string().matches(/^\d{10}$/, 'Phone number must be 10 digits').required("Father's Mobile Number is required"),
  motherName: Yup.string().required("Mother's Name is required"),
  motherMobile: Yup.string().matches(/^\d{10}$/, 'Phone number must be 10 digits').required("Mother's Mobile Number is required"),
  address: Yup.string().required('Address is required'),
  sport: Yup.string().required('Sport is required'),
});

const initialFormValues = {
  studentName: '',
  studentAge: '',
  gender: '',
  studentSchool: '',
  studentGrade: '',
  fatherName: '',
  fatherMobile: '',
  motherName: '',
  motherMobile: '',
  address: '',
  photo: null,
  sport: '',
};

const Academy = ({ paymentMethod }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const currentUserId = userDetails?.id;

  const { successData: academyDetails } = useSelector((state) => state.academyDetailsReducer || {});
  const { successData, errorInfo: academyRegistrationErrorInfo } = useSelector((state) => state.academyRegistrationReducer || {});
  const { successData: bookingDetails,errorInfo:bookingDetailsErrorInfo } = useSelector((state) => state.academyBookingReducer || {});


  const [selectedSession, setSelectedSession] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [hasAdmission, setHasAdmission] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [cartItems, setCartItems] = useState([]);
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [paymentsMethod, setPaymentsMethod] = useState('');

  const { session, date } = location.state || {};
  const { sessionDetails = {} } = location.state || {};

  const handleRadioChange = (event) => {
    const selectedAccountId = event.target.value;
    setSelectedStudent(selectedAccountId);
    console.log("Selected student:", selectedAccountId);

  };

  const handlePaymentMethodChange = (event) => {
    setPaymentsMethod(event.target.value);
  };
  const handleNewAdmission = () => {
    setShowForm(true);
    setIsPopupOpen(true);
  };
 
  let selectedSessionDetails = JSON.parse(localStorage.getItem('selectedSession'))

  console.log("selectedSessionDetails:", selectedSessionDetails);
  

  const handleBookNow = () => {
  
    if (!selectedStudent) {
      toast.error('Please select an account before booking');
      return;
    }
    let payload;
    console.log("paymentMethod:", paymentsMethod);
    console.log("userdetails:", userDetails.role);

    if (paymentsMethod === "cash") {
      payload = {
        academy_id: selectedStudent,
        user_id: currentUserId,
        session_id: selectedSessionDetails.session_id,
        payment_method: "cash",
        booked_by: userDetails.role, 
        amount:selectedSessionDetails.price,
        payment_type: "academy",
      }} else if(paymentsMethod === "UPI"){
      payload = {
        academy_id: selectedStudent,
        user_id: currentUserId,
        session_id: selectedSessionDetails.session_id,
        payment_method: "UPI",
        booked_by: userDetails.role,
        amount:selectedSessionDetails.price,
        transaction_id: transactionId,
        payment_type: "academy",
      }
    }
    if (payload) {
      dispatch(supervisorrAcademySessionBookingAction(payload));
      setIsBookingConfirmed(true);
    } else {
      toast.error('Please select a payment method');
    }
  };

  useEffect(() => {
    if (session && date) {
      setCartItems([{ name: `${session} Session - ${date}`, price: 100 }]);
    }
  }, [session, date]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHasAdmission(academyDetails && academyDetails.length > 0);

    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [academyDetails]);

  useEffect(() => {
    const existingAdmission = JSON.parse(localStorage.getItem('existingAdmission'));
    if (existingAdmission) {
      setHasAdmission(true);
    }
  }, []);


  useEffect(() => {
    const storedSession = localStorage.getItem('selectedSession');
    console.log("Stored Session:", storedSession);
    setSelectedSession(storedSession);
  }, []);

  useEffect(() => {
    dispatch(getacademyDetailsAction({
      endPoint: `users/academy-details?user_id=${userDetails.id}`,
    }));
  }, [dispatch, userDetails.id]);

  useEffect(() => {
    console.log("Academy Details:", academyDetails);
    console.log("Current User ID:", currentUserId);
  }, [academyDetails, currentUserId]);
  const handleSubmit = (values, { setSubmitting }) => {
    const formDataObj = new FormData();

    // Append each field to the FormData object
    formDataObj.append('student_name', values.studentName);
    formDataObj.append('student_age', values.studentAge);
    formDataObj.append('student_gender', values.gender);
    formDataObj.append('school_name', values.studentSchool);
    formDataObj.append('grade', values.studentGrade);
    formDataObj.append('father_name', values.fatherName);
    formDataObj.append('father_mobile_no', values.fatherMobile);
    formDataObj.append('mother_name', values.motherName);
    formDataObj.append('mother_mobile_no', values.motherMobile);
    formDataObj.append('address', values.address);
    formDataObj.append('sport', values.sport);
    formDataObj.append('photo', values.photo);
    formDataObj.append('user_id', userDetails.id);

    // Dispatch the action with the FormData
    dispatch(academyRegistrationAction(formDataObj));
    setIsPopupOpen(false);
    localStorage.setItem('academyFormData', JSON.stringify({
      studentName: values.studentName,
      sport: values.sport
    }));
  };

  useEffect(() => {
    console.log('Success Data:', successData);
    console.log('Error Info:', academyRegistrationErrorInfo);

    if (successData) {
      toast.success("New Student Admission successful!");
      setShowForm(false);
      setIsPopupOpen(false);
      setSelectedStudent(null);

      dispatch(getacademyDetailsAction({
        endPoint: `users/academy-details?user_id=${userDetails?.id}`,
      }));
    }

    if (academyRegistrationErrorInfo) {
      toast.error("New Student Admission failed! " + academyRegistrationErrorInfo);
    }
  }, [successData, academyRegistrationErrorInfo, dispatch, userDetails?.id]);


  return (
    <Container component="main" sx={{ width: { xs: "100%", sm: "100%" }, overflowX: 'hidden' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 5,
          padding: "10px 20px",
          width: {xs:"100%",sm:"90%"},
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
          marginLeft: { sm: '12%' }
        }}
      >
        <Typography sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} className="heading">Academy Admission</Typography>
        <hr
          style={{
            marginBottom: "20px",
            height: "2px",
            backgroundColor: "black",
            width: "80%",
            alignSelf: "center",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "20px",
            maxHeight: { xs: '170px', sm: "160px" },
            overflowY: "auto",
            width: { xs: "100%", sm: "100%" },
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#31303b',
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {academyDetails &&
              academyDetails.data &&
              academyDetails.data.length > 0 ? (
              <RadioGroup
                value={selectedStudent}
                onChange={handleRadioChange}
                sx={{ display: "flex", flexDirection: "row", justifyContent: 'flex-start', gap: 2 }}
              >
                {" "}
                {academyDetails.data.map(
                  (student) =>
                    student.user_id === currentUserId && (
                      <Box
                        key={student.academy_id}
                        sx={{ display: "flex", flexDirection: 'column' }}
                      >
                        {" "}
                        <Card
                          sx={{ minWidth: 300, height: 150, flex: "0 0 auto", boxShadow: '0px px 0px rgba(0, 0, 0, 0.1)', }}
                        >
                          {" "}
                          <CardContent
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {" "}
                            {student.photo ? (
                              <img
                                src={student.photo}
                                alt="profile"
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <AccountCircleIcon sx={{ width: 50, height: 50 }} />
                            )}
                            {" "}
                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}>
                              {" "}
                              <Typography variant="h6" component="div" sx={{ textAlign: 'center' }}>
                                {student.student_name}
                              </Typography>
                              {" "}
                              <FormControlLabel
                                sx={{ textAlign: 'center' }}
                                value={student.academy_id}
                                control={<Radio />}
                                label="Select Account"
                              />
                              {" "}
                            </Box>
                            {" "}
                          </CardContent>
                          {" "}
                        </Card>
                        {" "}
                      </Box>
                    )
                )}
                {" "}
                <Box
                  sx={{
                    textAlign: "center",
                    cursor: "pointer",
                    padding: "10px",
                    border: "1px dashed #31303b",
                    borderRadius: "8px",
                    height: 150,
                    '&:hover': {
                      backgroundColor: "#f0f0f0"
                    }
                  }}
                  onClick={handleNewAdmission}
                >
                  <AddIcon fontSize="large" color="black" />
                  <Typography variant="body1" color="black">Click to Add New Admission</Typography>
                </Box>

              </RadioGroup>
            ) : (
              <Box
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                  padding: "10px",
                  border: "1px dashed #31303b",
                  borderRadius: "8px",
                  height: 150,
                  '&:hover': {
                    backgroundColor: "#f0f0f0"
                  }
                }}
                onClick={handleNewAdmission}
              >
                <AddIcon fontSize="large" color="black" />
                <Typography variant="body1" color="black">Click to Add New Admission</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{
        marginTop: 3,
        textAlign: "center",
      }}>
        <RadioGroup
          value={paymentsMethod}
          onChange={handlePaymentMethodChange}
          sx={{ marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '2%' }}
        >
          <FormControlLabel disabled={isBookingConfirmed}
            value="cash" control={<Radio />} label="Cash" />
          <FormControlLabel disabled={isBookingConfirmed}
            value="UPI" control={<Radio />} label="UPI" />
        </RadioGroup>
        {paymentsMethod !== 'UPI' && paymentsMethod === 'cash' && (
          <Button
            onClick={handleBookNow}
            sx={{
              fontSize: '1rem',
              backgroundColor: '#31303b',
              color: '#fff',
              padding: '1% 5%',
              '&:disabled': {
                backgroundColor: '#ccc',
                color: '#000',
              },
            }}
          >
            Book Now
          </Button>
        )}
      </Box>

      <Dialog
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Typography variant='h5' sx={{ fontWeight: 'bold', marginBottom: '2%' }}>Academy Admission Form</Typography>
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form className="academyform">
                <label className="academylabel">
                  Student Name:
                  <Field
                    className="inputfeilds"
                    type="text"
                    name="studentName"
                  />
                  {errors.studentName && touched.studentName && (
                    <div className="error">{errors.studentName}</div>
                  )}
                </label>

                <label className="academylabel">
                  Age:
                  <Field
                    className="inputfeilds"
                    type="number"
                    name="studentAge"
                  />
                  {errors.studentAge && touched.studentAge && (
                    <div className="error">{errors.studentAge}</div>
                  )}
                </label>

                <label className="academylabel">
                  Gender:
                  <Field as="select" className="inputfeilds" name="gender">
                    <option value="">Select Gender</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="others">Others</option>
                  </Field>
                  {errors.gender && touched.gender && (
                    <div className="error">{errors.gender}</div>
                  )}
                </label>

                <label className="academylabel">
                  School:
                  <Field
                    className="inputfeilds"
                    type="text"
                    name="studentSchool"
                  />
                  {errors.studentSchool && touched.studentSchool && (
                    <div className="error">{errors.studentSchool}</div>
                  )}
                </label>

                <label className="academylabel">
                  Grade:
                  <Field
                    className="inputfeilds"
                    type="text"
                    name="studentGrade"
                  />
                  {errors.studentGrade && touched.studentGrade && (
                    <div className="error">{errors.studentGrade}</div>
                  )}
                </label>

                <label className="academylabel">
                  Father's Name:
                  <Field
                    className="inputfeilds"
                    type="text"
                    name="fatherName"
                  />
                  {errors.fatherName && touched.fatherName && (
                    <div className="error">{errors.fatherName}</div>
                  )}
                </label>

                <label className="academylabel">
                  Father's Mobile Number:
                  <Field
                    className="inputfeilds"
                    type="tel"
                    name="fatherMobile"
                    maxLength={10}
                  />
                  {errors.fatherMobile && touched.fatherMobile && (
                    <div className="error">{errors.fatherMobile}</div>
                  )}
                </label>

                <label className="academylabel">
                  Mother's Name:
                  <Field
                    className="inputfeilds"
                    type="text"
                    name="motherName"
                  />
                  {errors.motherName && touched.motherName && (
                    <div className="error">{errors.motherName}</div>
                  )}
                </label>

                <label className="academylabel">
                  Mother's Mobile Number:
                  <Field
                    className="inputfeilds"
                    type="tel"
                    name="motherMobile"
                    maxLength={10}
                  />
                  {errors.motherMobile && touched.motherMobile && (
                    <div className="error">{errors.motherMobile}</div>
                  )}
                </label>

                <label className="academylabel">
                  Address:
                  <Field className="inputfeilds" type="text" name="address" />
                  {errors.address && touched.address && (
                    <div className="error">{errors.address}</div>
                  )}
                </label>

                <label className="academylabel">
                  Upload Student Photo:
                  <input
                    className="inputfeilds"
                    type="file"
                    name="studentPhoto"
                    onChange={(event) => {
                      setFieldValue("photo", event.currentTarget.files[0]);
                    }}
                  />
                </label>

                <label className="academylabel">
                  Sport:
                  <Field as="select" className="inputfeilds" name="sport">
                    <option value="">--Select Sport--</option>
                    <option value="cricket">Cricket</option>
                    <option value="football">Football</option>
                  </Field>
                  {errors.sport && touched.sport && (
                    <div className="error">{errors.sport}</div>
                  )}
                </label>

                <button className="submitbtn" type="submit">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Paper elevation={3} sx={{ marginLeft: { xs: '0', sm: '32%' }, width: { xs: '100%', sm: '35% ' }, padding: '2rem', backgroundColor: '#fff', marginTop: { xs: '5%' }, borderRadius: '15px', marginBottom: '5%' }}>

        {isBookingConfirmed ?  (
          <Box sx={{ textAlign: 'center', }}>
            <Typography variant="h6">Your Booking Summary</Typography>
            <Typography variant="body1">Payment Method: {paymentsMethod}</Typography>
            <Typography>price : {selectedSessionDetails.price}</Typography>
            <Typography>Session ID : {selectedSessionDetails.session_id}</Typography>
            <Typography>Month : {selectedSessionDetails.month}</Typography>
            {paymentsMethod === 'UPI' && (
              <Typography variant="body1">Transaction ID: {transactionId}</Typography>
            )}
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center', padding: '2rem' }}>
            <Typography variant="h6" color="textSecondary">No booking details available</Typography>
          </Box>
        )}

        {!isBookingConfirmed && paymentsMethod === 'UPI' && (
          <Box sx={{ textAlign: 'center' }}>
            <img src={qrCode} alt="UPI QR Code" style={{ width: '200px', height: '300px' }} />
            <Typography variant="body1" sx={{ marginTop: '10px' }}>
              Scan this QR code to pay via UPI
            </Typography>
            <TextField
              label="Transaction ID"
              variant="outlined"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              sx={{ marginTop: '20px', width: '60%' }}
              inputProps={{ maxLength: 12 }}
            />
            <Button
              variant="contained"
              onClick={handleBookNow}
              sx={{
                backgroundColor: '#31303b',
                color: '#fff',
                marginTop: '2rem',
                width: '60%',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: '#000',
                },
              }}
              disabled={transactionId.length !== 12}
            >
              Book Now
            </Button>
          </Box>
        )}

        {isBookingConfirmed && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <CheckCircleIcon sx={{ color: 'green', fontSize: 30, marginRight: '10px' }} />
            <Typography variant="h6" sx={{ color: 'green' }}>
              Booking Confirmed
            </Typography>
          </Box>
        )}

      </Paper>

    </Container>
  );
};


export default Academy;