import { Button, Link, BottomNavigation, BottomNavigationAction, Typography, Toolbar, Box, AppBar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SuperT20Icon from '../../assets/SuperT20Icon.png';

export default function SearchAppBar() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [value, setValue] = useState(0);
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let userDetails = localStorage.getItem('userDetails');
    userDetails = JSON.parse(userDetails);
    if (userDetails?.token) {
      setIsAuthenticated(true);
      setUserRole(userDetails.role);
    }
  }, [isAuthenticated]);


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: '#31303b',
          padding: { xs: '0 1rem', md: '0 1.5rem' },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxWidth: { md: '1200px' },
            margin: '0',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'left',
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              fontWeight: 'bold',
            }}
          >
            <img src={SuperT20Icon} onClick={() => navigate('/')} alt="SuperT20" style={{ width: '150px', height: '50px', marginTop: { sm: '15px' }, cursor: 'pointer' }} />
          </Typography>

          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              gap: { xs: 3, md: 4 },
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: '20%',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Link color="inherit" underline="none" sx={{ fontSize: '1.15rem' }} onClick={() => navigate('/')}>
              HOME
            </Link>
            <Link color="inherit" underline="none" sx={{ fontSize: '1.15rem' }} onClick={() => navigate('/booking')}>
              PLAY
            </Link>
            {/* <Link color="inherit" underline="none" sx={{ fontSize: '1.15rem' }} onClick={() => navigate('/academy-booking')}>
              JOIN ACADEMY
            </Link> */}
          </Box>
          {userRole === 'admin' && (
            <Link
              color="inherit"
              underline="none"
              sx={{ marginRight: {xs:'15%',sm:'2%'},'&:hover': { cursor: 'pointer' } }}
              onClick={() => navigate('/admin')}
            >
              GO TO ADMIN
            </Link>
          )}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              backgroundColor: 'gray',
              borderRadius: '20px',
              width: isAuthenticated ? '7rem' : '11rem',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                if (isAuthenticated) {
                  navigate('/profile');
                } else {
                  navigate('/login');
                }
              }}
              sx={{
                width: '100%',
                backgroundColor: isAuthenticated ? 'white' : 'white',
                color: isAuthenticated ? 'black' : 'black',
                height: '40px',
                borderRadius: '20px',
                '&:hover': {
                  backgroundColor: isAuthenticated ? 'black' : 'black',
                  color: isAuthenticated ? 'white' : 'white',
                },
              }}
            >
              {isAuthenticated ? (
                <>
                  <AccountCircleIcon />
                  Profile
                </>
              ) : (
                'Login / Signup'
              )}
            </Button>

          </Box>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: { xs: 'flex', sm: 'none' },
          position: 'relative',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: '#31303b',
        }}
      >
        <BottomNavigation
          sx={{ backgroundColor: '#31303b', position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1000 }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction
            onClick={() => navigate('/')}
            label="Home"
            icon={<HomeIcon />}
            sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
          />
          <BottomNavigationAction
            onClick={() => navigate('/booking')}
            label="Play"
            icon={<PlayCircleFilledIcon />}
            sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
          />
          <BottomNavigationAction
            onClick={() => navigate('/academy-booking')}
            label="Academy"
            icon={<GroupAddOutlinedIcon />}
            sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
          />
          <BottomNavigationAction
            onClick={() => navigate(isAuthenticated ? '/profile' : '/login')}
            label={isAuthenticated ? "Profile" : "Login"}
            icon={<AccountCircleIcon />}
            sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
          />
        </BottomNavigation>
      </Box>
    </Box>
  );
}
