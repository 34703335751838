import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { getadminPaymentsAction } from '../../store/actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (`0${today.getMonth() + 1}`).slice(-2);
  const day = (`0${today.getDate()}`).slice(-2); 
  return `${year}-${month}-${day}`;
};

const AdminPayment = () => {
  const slotTime = [
    { id: 0, start_time: '12:00 AM' }, { id: 1, start_time: '1:00 AM' }, { id: 2, start_time: '2:00 AM' },
    { id: 3, start_time: '3:00 AM' }, { id: 4, start_time: '4:00 AM' }, { id: 5, start_time: '5:00 AM' },
    { id: 6, start_time: '6:00 AM' }, { id: 7, start_time: '7:00 AM' }, { id: 8, start_time: '8:00 AM' },
    { id: 9, start_time: '9:00 AM' }, { id: 10, start_time: '10:00 AM' }, { id: 11, start_time: '11:00 AM' },
    { id: 12, start_time: '12:00 PM' }, { id: 13, start_time: '1:00 PM' }, { id: 14, start_time: '2:00 PM' },
    { id: 15, start_time: '3:00 PM' }, { id: 16, start_time: '4:00 PM' }, { id: 17, start_time: '5:00 PM' },
    { id: 18, start_time: '6:00 PM' }, { id: 19, start_time: '7:00 PM' }, { id: 20, start_time: '8:00 PM' },
    { id: 21, start_time: '9:00 PM' }, { id: 22, start_time: '10:00 PM' }, { id: 23, start_time: '11:00 PM' }
  ];

  const dispatch = useDispatch();
  const { successData, loading } = useSelector((state) => state.AdminPaymentReducer);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  const headers = ['Payment Date', 'Name', 'Mobile Number', 'Booking_Id', 'Date & Time', 'Amount', 'Payment Type','Payment Method', 'Transaction ID'];

  const [searchDate, setSearchDate] = useState(getCurrentDate());
  const [bookingDate, setBookingDate] = useState('');
  const [paymentMethod, setPaymentMethod] = useState("All");

  useEffect(() => {
    dispatch(getadminPaymentsAction());
  }, [dispatch]);

// Update the filteredPayments logic to handle empty booking date
const filteredPayments = successData?.filter((payment) => {
  const dateMatches = searchDate ? new Date(payment?.payment_date).toISOString().split('T')[0] === searchDate : true;
  const paymentMethodMatches = paymentMethod === "All" || payment?.payment_method === paymentMethod;
  const statusMatches = payment?.payment_status === "success";
  const bookingDateMatches = bookingDate ? 
      (payment?.payment_type === "slot" ? 
          payment?.Booking?.DailySlot?.date === bookingDate : 
          true) : true;
  
  return dateMatches && paymentMethodMatches && statusMatches && bookingDateMatches;
}).sort((a, b) => {
  if (a.payment_type === "slot" && b.payment_type === "slot") {
      const timeA = a?.Booking?.Slot?.start_time || '';
      const timeB = b?.Booking?.Slot?.start_time || '';
      return timeA.localeCompare(timeB);
  }
  if (a.payment_type === "academy" && b.payment_type === "academy") {
      const slotIdA = a?.AcademyBooking?.AcademySession?.slot_id || 0;
      const slotIdB = b?.AcademyBooking?.AcademySession?.slot_id || 0;
      return slotIdA - slotIdB;
  }
  return 0;
});


  const getStartTimeFromSlotId = (slotId) => {
    const slot = slotTime.find((slot) => slot.id === slotId);
    return slot ? slot.start_time : "N/A";
  };

  const paymentsForTotal = successData?.filter((payment) => {
    const dateMatches = searchDate ? new Date(payment?.payment_date).toISOString().split('T')[0] === searchDate : true;
    const paymentMethodMatches = paymentMethod === "All" || payment?.payment_method === paymentMethod;
    const statusMatches = payment?.payment_status === "success";
    return dateMatches && paymentMethodMatches && statusMatches;
  });

  // Calculate total amount using paymentsForTotal
  const totalAmount = paymentsForTotal?.reduce((sum, payment) => 
    sum + parseInt(payment?.amount || 0, 10), 0);

  // Calculate booking date specific sum from paymentsForTotal
  const todayBookingSum = paymentsForTotal?.filter(payment =>
    bookingDate ? 
      (payment?.payment_type === "slot" && payment?.Booking?.DailySlot?.date === bookingDate) :
      (payment?.payment_type === "slot" && payment?.Booking?.DailySlot?.date === searchDate)
  ).reduce((sum, payment) => sum + parseInt(payment?.amount || 0, 10), 0);
  


  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5rem', marginLeft: { md: '220px', xs: '0' }, marginBottom:{xs:'15',sm:'0%'} }}>
      <Typography variant="h4" sx={{ marginBottom: '2rem', color: '#31303b' }}><b>Payment Details</b></Typography>

      <Box sx={{ display: 'flex', gap: '1.5rem', width: '100%', justifyContent: 'center', marginBottom: '1.5rem', flexWrap: 'wrap' }}>
        <TextField
          type="date"
          label="Filter by Payment Date"
          variant="outlined"
          value={searchDate}
          onChange={(e) => setSearchDate(e.target.value)}
          sx={{ width: '25%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        
        {/* <TextField
          type="date"
          label="Filter by Booking Date"
          variant="outlined"
          value={bookingDate}
          onChange={(e) => setBookingDate(e.target.value)}
          sx={{ width: '25%' }}
          InputLabelProps={{
            shrink: true,
          }}
        /> */}

        <FormControl sx={{ width: '25%' }}>
          <InputLabel id="payment-method-label">Payment Method</InputLabel>
          <Select
            labelId="payment-method-label"
            label="Payment Method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <MenuItem value='All'>All</MenuItem>
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="Razorpay">Razorpay</MenuItem>
          </Select>
        </FormControl>
      </Box>
          <Typography variant="h6" sx={{ alignSelf: 'flex-end', marginRight: '2.5%' }}>
            {paymentMethod && `Total Amount: ₹${totalAmount || 0}`}
          </Typography>
          <Typography variant="h6" sx={{ alignSelf: 'flex-end', marginRight: '2.5%' }}>
            {`Today's: ₹${todayBookingSum || 0}`}
          </Typography>
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : (
        <>
          {!isMobile ? (
            <Box sx={{ overflowX: 'auto', width: '90%' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                <thead style={{ backgroundColor: '#31303b', color: 'white' }}>
                  <tr>
                    {headers.map((header, index) => (
                      <th key={index} style={{ border: '1px solid black', padding: '10px', minWidth: '120px' }}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredPayments?.length > 0 ? (
                    filteredPayments.map((payment, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>
                          {payment?.payment_date ? new Date(payment.payment_date).toISOString().split('T')[0] : "N/A"}
                        </td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>{payment?.user_name || "N/A"}</td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>{payment?.mobile_no || "N/A"}</td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>{payment?.booking_id || "N/A"}</td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>
                        {payment?.payment_type === "slot"
                            ? <div>
                              {payment?.Booking?.DailySlot?.date || "N/A"}<br />
                              {convertTo12HourFormat(payment?.Booking?.Slot?.start_time) || "N/A"}
                            </div>
                            : payment?.payment_type === "academy"
                              ? getStartTimeFromSlotId(payment?.AcademyBooking?.AcademySession?.slot_id) || "N/A"
                              : "N/A"}
                        </td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>{payment?.amount || "N/A"}</td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>{payment?.payment_type || "N/A"}</td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center' }}>{payment?.payment_method || "N/A"}</td>
                        <td style={{ border: '1px solid black', padding: '10px', textAlign: 'center', minWidth: '150px' }}>{payment?.transaction_id || "N/A"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} style={{ textAlign: 'center', border: '1px solid black' }}>No payment details available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Box>
          ) : (
            <Box sx={{ width: '95%', display: 'flex', flexDirection: 'column', gap: '1rem', overflowX: 'hidden' }}>
              {filteredPayments?.length > 0 ? (
                filteredPayments.map((payment, index) => (
                  <Box key={index} sx={{ border: '1px solid black', padding: '1rem', borderRadius: '8px', backgroundColor: '#fff' }}>
                    <Typography><strong>Date:</strong> {payment?.payment_date ? new Date(payment.payment_date).toISOString().split('T')[0] : 'N/A'}</Typography>
                    <Typography><strong>Name:</strong> {payment?.user_name || 'N/A'}</Typography>
                    <Typography><strong>Mobile Number:</strong> {payment?.mobile_no || 'N/A'}</Typography>
                    <Typography><strong>Booking ID:</strong> {payment?.booking_id || 'N/A'}</Typography>
                    <Typography>
                      <strong>Slot Time:</strong>
                      {payment?.payment_type === "slot"
                        ? <div>
                          {payment?.Booking?.DailySlot?.date || "N/A"}<br />
                          {convertTo12HourFormat(payment?.Booking?.Slot?.start_time) || "N/A"}
                        </div>
                        : payment?.payment_type === "academy"
                          ? getStartTimeFromSlotId(payment?.AcademyBooking?.AcademySession?.slot_id) || "N/A"
                          : "N/A"}
                    </Typography>
                    <Typography><strong>Amount:</strong> {payment?.amount || 'N/A'}</Typography>
                    <Typography><strong>Payment Method:</strong> {payment?.payment_method || 'N/A'}</Typography>
                    <Typography><strong>Transaction ID:</strong> {payment?.transaction_id || 'N/A'}</Typography>
                  </Box>
                ))
              ) : (
                <Typography>No payment details available.</Typography>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AdminPayment;
