import sliceCreator from "../../index.js";
import { ADMIN_USER_LIST } from "../../../constants/index.js";

const _adminUserListAction = sliceCreator("ADMINUSERLIST",ADMIN_USER_LIST,"get");

const {asyncAction: getAdminUserListAction, reducer, clearData: clearAdminUserListAction,} = _adminUserListAction;

export default reducer;

export { getAdminUserListAction, clearAdminUserListAction };
