import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { getCurrentBookings } from '../../store/actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {cancleBookingAction} from '../../store/actions';
import toast from 'react-hot-toast';

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (`0${today.getMonth() + 1}`).slice(-2);
  const day = (`0${today.getDate()}`).slice(-2); 
  return `${year}-${month}-${day}`;
};

const CurrentBookings = () => {
  const dispatch = useDispatch();
  const { successData, loading } = useSelector(state => state.adminCurrentBookingsReducer);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    dispatch(getCurrentBookings());
  }, [dispatch, refresh]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };

  const headers = ["Date", 'Name', 'Mobile Number', 'Booking_Id', "Start Time", "Is_Bonus", "Amount", "Action"];

  const filteredBookings = successData?.filter((booking) => {
    if (selectedDate) {
      return booking?.DailySlot?.date === selectedDate;
    }
    return true;
  }).sort((a, b) => {
    const timeA = a.Slot.start_time.split(':').map(Number);
    const timeB = b.Slot.start_time.split(':').map(Number);
    if (timeA[0] !== timeB[0]) {
      return timeA[0] - timeB[0];
    }
  });

  const handleCancelClick = (booking) => {

    console.log("Cancel clicked for booking:", booking);
    setSelectedBooking(booking);
    setOpenDialog(true);
};

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedBooking(null);
  };

  const handleSubmit = () => {
    if (selectedBooking) {
      dispatch(cancleBookingAction({
        endPoint: `admin/cancel-booking/${selectedBooking?.booking_id}`
      }))
      handleCloseDialog();
    }
  };

  console.log("filteredBookings", filteredBookings);
  
  const totalAmount = filteredBookings?.reduce((sum, payment) => {
    if (!payment?.is_bonus) {
      return sum + parseInt(payment?.Slot.base_price || 0, 10);
    }
    return sum;
  }, 0)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5rem', marginLeft: { md: '220px', xs: '0' }, marginBottom: { xs: '15%', sm: '2%' } }}>
      <Typography variant="h4" sx={{ marginBottom: '2rem', fontWeight: 'bold' }}>Current Bookings</Typography>
      
      <TextField
        type="date"
        label="Filter by Date"
        variant="outlined"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        sx={{ marginBottom: '1.5rem', width: '40%' }}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Typography variant="h6" sx={{ alignSelf: 'flex-end', marginRight: '2.5%' }}>
        {successData && `Total Amount: ₹${totalAmount || 0}`}
      </Typography>

      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : (
        <>
          {!isMobile ? (
            <table style={{ width: '95%', borderCollapse: 'collapse', border: '1px solid black' }}>
              <thead style={{ backgroundColor: '#31303b', color: 'white' }}>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} style={{ padding: '10px', border: '1px solid black' }}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredBookings?.length > 0 ? (
                  filteredBookings.map((booking, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.DailySlot?.date || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.user?.full_name || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.user?.mobile_no || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.booking_id || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{convertTo12HourFormat(booking?.Slot.start_time) || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.is_bonus ? 'true' : 'false'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.slot?.base_price || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>
                        <Button variant="contained" color="secondary" onClick={() => handleCancelClick(booking)}>Cancel</Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} style={{ textAlign: 'center', border: '1px solid black' }}>No details available for the selected date.</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <Box sx={{ width: '95%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {filteredBookings?.length > 0 ? (
                filteredBookings.map((booking, index) => (
                  <Box key={index} sx={{ border: '1px solid black', padding: '1rem', borderRadius: '8px', backgroundColor: '#f5f5f5' }}>
                    <Typography><strong>Date:</strong> {booking?.DailySlot?.date || 'N/A'}</Typography>
                    <Typography><strong>Name:</strong> {booking?.user?.full_name || 'N/A'}</Typography>
                    <Typography><strong>Mobile Number:</strong> {booking?.user?.mobile_no || 'N/A'}</Typography>
                    <Typography><strong>Booking ID:</strong> {booking?.booking_id || 'N/A'}</Typography>
                    <Typography><strong>Start Time:</strong> {convertTo12HourFormat(booking?.Slot.start_time) || 'N/A'}</Typography>
                    <Typography><strong>Is Bonus:</strong> {booking?.is_bonus ? 'true' : 'false'}</Typography>
                    <Typography><strong>Amount:</strong> {booking?.slot?.base_price || 'N/A'}</Typography>
                    <Button variant="contained" color="secondary" onClick={() => handleCancelClick(booking)}>Cancel</Button>
                  </Box>
                ))
              ) : (
                <Typography>No details available for the selected date.</Typography>
              )}
            </Box>
          )}
        </>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle sx={{ textAlign: 'center' }}>Cancel Booking</DialogTitle>
        <DialogContent>
          {selectedBooking && (
            <>
              <Typography><strong>Date:</strong> {selectedBooking?.DailySlot?.date || 'N/A'}</Typography>
              <Typography><strong>Name:</strong> {selectedBooking?.user?.full_name || 'N/A'}</Typography>
              <Typography><strong>Booking ID:</strong> {selectedBooking?.booking_id || 'N/A'}</Typography>
              <Typography><strong>Start Time:</strong> {convertTo12HourFormat(selectedBooking?.Slot.start_time) || 'N/A'}</Typography>
            </>
          )}
          <Box mt={3}>
            <Typography>Are you sure you want to cancel the booking?</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">No</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">Yes</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CurrentBookings;
