import axios from 'axios';
import {BASE_URL,ACADEMY_REGISTRATION,ACADEMY_UPDATE_PROFILE} from '../constants'
import { createAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAccessToken } from '../utils/refreshAccessToken';


const userDetails = JSON.parse(localStorage.getItem('userDetails'));


const getApi = (endPoint) => ({     
    get: async () => {
        //getting  parsed token from  local storage userDetails


        const token = userDetails?.token;
 

        let headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }

        // Check if the endpoint requires a file download (e.g., user list download)
        if (endPoint === "admin/download-user-list") {  
            headers = {
                Authorization: `Bearer ${token}`,
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Expect Excel file
            };
        }

        let options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'get',
            headers: headers,
            withCredentials: true,
            responseType: endPoint === "admin/download-user-list" ? "blob" : "json",  // Handle as blob for file download
        }

        try {
            return await axios(options);
        } catch (error) {
            if (error.response?.status === 401) {
                const newToken = await refreshAccessToken(); // Get new token
                options.headers.Authorization = `Bearer ${newToken}`; // Update header with new token
                return await axios(options); // Retry the original request
            }
            throw error;
        }
    },
    post: async data => {
        const token = userDetails?.token;

        let headers = {
            
        }
        if(token){
            headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        }

           const options = {
                        url: `${BASE_URL}${endPoint}`,
                        method: 'post',
                        data,
                        headers: headers,
                        withCredentials: true
                    }
                
            const formDataEndpoints = [ACADEMY_REGISTRATION];
            if (formDataEndpoints.some(endpoint => endPoint.startsWith(endpoint))) {
              options.data = data;
              options.headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
              };
            }   
            // console.log("Iam options :",options);    
        try {
            return await axios(options);
        } catch (error) {
            if (error.response?.status === 401) {
                const newToken = await refreshAccessToken(); // Get new token
                options.headers.Authorization = `Bearer ${newToken}`; // Update header with new token
                return await axios(options); // Retry the original request
            }
            throw error;
        }
         
    },   
    patch: async data => {
        const token = userDetails?.token;
        const options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'patch',
            data,
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true,
        }  
        const formDataEndpoints = [ACADEMY_UPDATE_PROFILE];

        if (formDataEndpoints.some(endpoint => endPoint.startsWith(endpoint))) {
          options.data = data.formDataObj;
          options.headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          };
        }
        try {
            return await axios(options);
        } catch (error) {
            if (error.response?.status === 401) {
                const newToken = await refreshAccessToken(); // Get new token
                options.headers.Authorization = `Bearer ${newToken}`; // Update header with new token
                return await axios(options); // Retry the original request
            }
            throw error;
        }
    },   
    put: async data => {
        const token = userDetails?.token;
        const options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'put',
            data,
            headers: {
                Authorization: `Token ${token}`
            },
            withCredentials: true
        }  
        try {
            return await axios(options);
        } catch (error) {
            if (error.response?.status === 401) {
                const newToken = await refreshAccessToken(); // Get new token
                options.headers.Authorization = `Bearer ${newToken}`; // Update header with new token
                return await axios(options); // Retry the original request
            }
            throw error;
        }
    },   
    delete: async data => {
        const token = userDetails?.token;
        const options = {
            url: `${BASE_URL}${endPoint}`,
            method: 'delete',
            data,
            headers: {
                Authorization: `Token ${token}`
            },
            withCredentials: true

        }  
        try {
            return await axios(options);
        } catch (error) {
            if (error.response?.status === 401) {
                const newToken = await refreshAccessToken(); // Get new token
                options.headers.Authorization = `Bearer ${newToken}`; // Update header with new token
                return await axios(options); // Retry the original request
            }
            throw error;
        }
    },   
})



export default function sliceCreator(sliceName, endPoint, method) {
                                     
    const action = createAsyncThunk(
        (`${sliceName}/${method}`).toUpperCase(),
        async (data,  { rejectWithValue }) => {
            // const {_endPoint} = data;
            // if(_endPoint) endPoint = endPoint + _endPoint;
            try {
                const response = await getApi(data?.endPoint || endPoint)[method](data);
                // console.log(response, "response")
                return response.data
            }catch (err) {
                // console.log(err.response, 'error');
                // Use `err.response.data` as `action.payload` for a `rejected` action,
                // by explicitly returning it using the `rejectWithValue()` utility
                return rejectWithValue(err.response.data)
            }
        }
    )
    
    const clearData = `${(method).toUpperCase()}/clear`;

    const slice = createSlice({
        name: (sliceName).toUpperCase(),
        initialState: {    
            loading: false,
            successData: null,
            error: false,
            errorInfo: null                  
        },
        reducers: {
            [clearData]: () => {return {}}
        },
        extraReducers: builder => {
            builder
                .addCase(action.pending, (state, action) => {
                    state.loading = true;
                    state.successData = null;
                    state.error = false;
                    state.errorInfo = null;
                })
                .addCase(action.rejected, (state, action) => {
                    // console.log(action);
                    state.loading = false;
                    state.successData = null;
                    state.error = true;
                    state.errorInfo = action.payload?.message || action.payload?.non_field_errors;
                })
                .addCase(action.fulfilled, (state, action) => {
                    state.loading = false;
                    state.successData = action.payload === "" ? {success: true} : action.payload;
                    state.error = false;
                    state.errorInfo = null;
                });
        }

    })

    return {
        reducer: slice.reducer,
        asyncAction: action,
        clearData: slice.actions[clearData] 
    };
}